
<div class="logo">
	<a href="https://www.creative-tim.com" class="simple-text logo-mini">
        <div class="logo-image-small">
            <img src="./assets/img/angular2-logo-white.png"/>
        </div>
	</a>

	<a href="https://www.creative-tim.com" class="simple-text logo-normal">
		Creative Tim
	</a>
</div>


<div class="sidebar-wrapper">

  <div class="user">
    <div class="photo">
      <img src="./assets/img/faces/ayo-ogunseinde-2.jpg" />
    </div>
    <div class="info">
      <a data-toggle="collapse" href="#collapseExample" class="collapsed">
        <span>
          Chet Faker
          <b class="caret"></b>
        </span>
      </a>
      <div class="clearfix"></div>
      <div class="collapse" id="collapseExample">
        <ul class="nav">
          <li>
            <a href="javascript:void(0)">
              <span class="sidebar-mini-icon">MP</span>
              <span class="sidebar-normal">My Profile</span>
            </a>
          </li>
          <li>
            <a href="javascript:void(0)">
              <span class="sidebar-mini-icon">EP</span>
              <span class="sidebar-normal">Edit Profile</span>
            </a>
          </li>
          <li>
            <a href="javascript:void(0)">
              <span class="sidebar-mini-icon">S</span>
              <span class="sidebar-normal">Settings</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
	<!-- <div *ngIf="isNotMobileMenu()">
        <form class="navbar-form navbar-left navbar-search-form" role="search">
            <div class="input-group">
                <span class="input-group-addon"><i class="fa fa-search"></i></span>
                <input type="text" value="" class="form-control" placeholder="Search...">
            </div>
        </form>
        <ul class="nav nav-mobile-menu">
            <li>
                <a href="#stats" class="dropdown-toggle btn-magnify" data-toggle="dropdown">
                    <i class="ti-panel"></i>
                    <p>Stats</p>
                </a>
            </li>
            <li class="dropdown">
                <a href="#notifications" class="dropdown-toggle btn-rotate" data-toggle="dropdown">
                    <i class="ti-bell"></i>
                    <span class="notification">5</span>
                    <p class="hidden-md hidden-lg">
                        Notifications
                        <b class="caret"></b>
                    </p>
                </a>
                <ul class="dropdown-menu">
                    <li><a href="#not1">Notification 1</a></li>
                    <li><a href="#not2">Notification 2</a></li>
                    <li><a href="#not3">Notification 3</a></li>
                    <li><a href="#not4">Notification 4</a></li>
                    <li><a href="#another">Another notification</a></li>
                </ul>
            </li>
            <li>
                <a class="btn-rotate">
                    <i class="ti-settings"></i>
                    <p class="hidden-md hidden-lg">
                        Settings
                    </p>
                </a>
            </li>
        </ul>
    </div> -->
        <ul class="nav">
            <li routerLinkActive="active" *ngFor="let menuitem of menuItems">
                <!--If is a single link-->
                <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'">
                    <i class="{{menuitem.icontype}}"></i>
                    <p>{{menuitem.title}}</p>
                </a>
                <!--If it have a submenu-->
                <a data-toggle="collapse" href="#{{menuitem.collapse}}" *ngIf="menuitem.type === 'sub'">
                    <i class="{{menuitem.icontype}}"></i>
                    <p>{{menuitem.title}}<b class="caret"></b></p>
                </a>

                <!--Display the submenu items-->
                <div id="{{menuitem.collapse}}" class="collapse" *ngIf="menuitem.type === 'sub'">
                    <ul class="nav">
                        <li routerLinkActive="active" *ngFor="let childitem of menuitem.children">
                            <a [routerLink]="[menuitem.path, childitem.path]">
                                <span class="sidebar-mini-icon">{{childitem.ab}}</span>
                                <span class="sidebar-normal">{{childitem.title}}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>

</div>
