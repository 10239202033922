<div class="container center">
    <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-6 ml-auto mr-auto">
            <form [formGroup]="loginFormGroup"
                  (ngSubmit)="login()"
                  class="form">
                <div class="card card-login card-hidden">
                    <div class="card-header card-header-rose text-center">
                        <h4 class="card-title">Autentificare</h4>
                    </div>
                    <div class="card-body ">
                        <span class="bmd-form-group">
                    </span>
                        <span class="bmd-form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="material-icons">person</i>
                      </span>
                    </div>
                    <input formControlName="username" class="form-control" placeholder="Username..." type="text">
                  </div>
                </span>
                        <span class="bmd-form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="material-icons">lock_outline</i>
                      </span>
                    </div>
                    <input formControlName="password" class="form-control" placeholder="Password..." type="password">
                  </div>
                </span>
                    </div>
                    <div class="card-footer justify-content-center">
                        <button class="btn btn-rose btn-link btn-lg" type="submit">LOGIN</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>


