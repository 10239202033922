
<div class="wrapper">
    <div class="sidebar" data-color="brown" data-active-color="danger">
        <sidebar-cmp></sidebar-cmp>
    </div>
    <div class="main-panel">
        <navbar-cmp></navbar-cmp>
        <router-outlet></router-outlet>
        <div *ngIf="!isMap()">
            <footer-cmp></footer-cmp>
        </div>
    </div>

</div>
<fixedplugin-cmp></fixedplugin-cmp>
