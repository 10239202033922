import {Directive, ElementRef} from '@angular/core';

@Directive({
    selector: '[appFormAutoFocus]',
    exportAs: 'focusInvalidInput'
})
export class FormAutoFocusDirective {

    constructor(private el: ElementRef) {
    }

    public focus(): void {
        const invalidControl = this.el.nativeElement.querySelector('.ng-invalid');
        console.log('test', invalidControl);
        if (invalidControl) {
            invalidControl.focus();
            console.log('test');
        }
    }
}
