import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {isLoggedIn} from '../../domain/utils/utils';
import {Observable, of} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoginGuard implements CanActivate {

    constructor(private readonly authService: AuthService,
                private readonly router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.isLoggedIn(route);
    }

    private isLoggedIn(route: ActivatedRouteSnapshot) {
        if (isLoggedIn()) {
            return of(true);
        }
        return of(this.router.parseUrl('/login'));
    }
}
