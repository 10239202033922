import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {getLoggedInUserRole} from '../../domain/utils/utils';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import jwtDecode from 'jwt-decode';
import {Jwtoken} from '../../domain/jwtoken';
import {NotificationService} from '../services/notification.service';
import {Constants} from '../../domain/utils/constants';
import {NotificationType} from '../../domain/enums/notification-type';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginFormGroup: FormGroup;

  constructor(private readonly formBuilder: FormBuilder,
              private readonly authService: AuthService,
              private readonly notificationService: NotificationService,
              private readonly router: Router) { }

  ngOnInit(): void {
    this.initFormGroup();
  }

  initFormGroup(): void {
    this.loginFormGroup = this.formBuilder.group({
      'username': ['', [Validators.required]],
      'password': ['', Validators.required]
    });
  }

  login(): void {
    if (this.loginFormGroup.valid) {
      const login = {
        username: this.loginFormGroup.get('username')?.value,
        password: this.loginFormGroup.get('password')?.value
      };
      console.log(login);
      this.authService.login(login).subscribe(response => {
        this.setSession(response);
        console.log(getLoggedInUserRole());
        this.router.navigate(['/manager/profile'], {replaceUrl: true});
      }, () => this.notificationService.showNotification(Constants.INVALID_LOGIN, NotificationType.DANGER));
    }
  }

  private setSession(token: Jwtoken): void {
    const jwt_decoded: any = jwtDecode(token.token);

    localStorage.setItem('token', token.token);
    localStorage.setItem('user_id', jwt_decoded.user.id);
    localStorage.setItem('role', jwt_decoded.user.role);
    localStorage.setItem('exp', jwt_decoded.exp);
    localStorage.setItem('iat', jwt_decoded.iat);
    localStorage.setItem('name', `${jwt_decoded.user.firstName} ${jwt_decoded.user.lastName}`);

  }
}
