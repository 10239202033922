import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {SidenavComponent} from './sidenav.component';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  imports: [RouterModule, CommonModule, NgbModule],
  declarations: [ SidenavComponent ],
  exports: [ SidenavComponent ]
})

export class SidenavModule {}
