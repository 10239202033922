import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppComponent} from './app.component';
import {SidebarModule} from './sidebar/sidebar.module';
import {FixedPluginModule} from './shared/fixedplugin/fixedplugin.module';
import {FooterModule} from './shared/footer/footer.module';
import {NavbarModule} from './shared/navbar/navbar.module';
import {AppRoutes} from './app.routing';
import {LoginComponent} from "./prod/login/login.component";
import {HomepageComponent} from "./prod/homepage/homepage.component";
import {NavbarComponent} from "./prod/navbar/navbar.component";
import {FileValueAccessorDirective} from "./prod/directives/file-value-accessor.directive";
import {FileValidatorDirective} from "./prod/directives/file-validator.directive";
import {FormAutoFocusDirective} from "./prod/directives/form-auto-focus.directive";
import {ReportTimesheetComponent} from "./prod/report-check/waiting-report/report-timesheet/report-timesheet.component";
import {SidenavModule} from "./prod/sidenav/sidenav.module";
import {AdminLayoutComponent} from "./layouts/admin/admin-layout.component";
import {AuthLayoutComponent} from "./layouts/auth/auth-layout.component";
import {HttpClientModule} from "@angular/common/http";
import {CommonModule, DatePipe} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        FormsModule,
        RouterModule.forRoot(AppRoutes, {
            useHash: true
        }),
        NgbModule,
        SidebarModule,
        NavbarModule,
        HttpClientModule,
        FooterModule,
        FixedPluginModule,
        SidenavModule,
        ReactiveFormsModule
    ],
    declarations: [
        LoginComponent,
        HomepageComponent,
        NavbarComponent,
        FileValueAccessorDirective,
        FileValidatorDirective,
        ReportTimesheetComponent,
        FormAutoFocusDirective,
        AppComponent,
        AdminLayoutComponent,
        AuthLayoutComponent
    ],
    exports: [],
    bootstrap: [AppComponent],
    providers: [DatePipe]
})

export class AppModule {
}
