export class Constants {
    static readonly CONFIRM_QUESTION = 'Sunteti sigur?';
    static readonly CONFIRM_TEXT = 'Stergerea aceasta nu va mai putea fi recuperata!';

    static readonly SUCCESS_TITLE = 'Sters!';
    static readonly SUCCESS_TEXT = 'Stergerea a fost efectuata!';

    static readonly APPROVE_TIMESHEET_TEXT = 'Doriti aprobarea acestui pontaj?';
    static readonly REMOVE_APPROVAL_TIMESHEET_TEXT = 'Doriti revocarea aprobarii acestui pontaj?';

    static readonly APPROVE_TIMESHEET_SUCCESS_TEXT = 'Pontajul a fost aprobat!';
    static readonly APPROVE_TIMESHEET_SUCCESS_TITLE = 'Aprobat!';
    static readonly REMOVE_APPROVAL_TIMESHEET_SUCCESS_TEXT = 'Aprobarea pontajului a fost revocata!';
    static readonly REMOVE_APPROVAL_TIMESHEET_SUCCESS_TITLE = 'Anulat!';

    static readonly APPROVE_REPORT_TEXT = 'Doriti aprobarea acestui raport?';
    static readonly REMOVE_APPROVAL_REPORT_TEXT = 'Doriti revocarea aprobarii acestui raport?';

    static readonly REJECT_ARCHIVE_TILE = 'Arhiva nu a putut fi descarcata';
    static readonly REJECT_ARCHIVE_TEXT = 'Lipsesc urmatoarele fisiere: <br>';

    static readonly APPROVE_REPORT_SUCCESS_TEXT = 'Raportul a fost aprobat!';
    static readonly APPROVE_REPORT_SUCCESS_TITLE = 'Aprobat!';
    static readonly REMOVE_APPROVAL_REPORT_SUCCESS_TEXT = 'Aprobarea raportului a fost revocata!';
    static readonly REMOVE_APPROVAL_REPORT_SUCCESS_TITLE = 'Anulat!';

    static readonly ACTIVITY_DELETE_ERROR: 'Activitatea e folosita';

    static readonly FILE_UPLOAD_FAILURE = 'Nu s-au putut incarca fisierele';
    static readonly INVALID_FILES_EXTENSIONS = 'Fisierele de incarcat pot avea doar format PDF';

    static readonly NO_REPORT_IN_THIS_MONTH = 'Nu exista nici un raport de activitate pentru aceasta luna!';

    static readonly INVALID_LOGIN = 'Nume de utilizator sau parolă greșită! Vă rugăm să introduceți datele corect!';
    static readonly GENERAL_ERROR = 'Eroare server';
}
