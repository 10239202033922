import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Jwtoken} from '../../domain/jwtoken';
import {environment} from '../../../environments/environment';
import {Login} from '../../domain/login';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private readonly http: HttpClient) { }

  login(login: Login): Observable<Jwtoken> {
    return this.http.post<Jwtoken>(environment.apiBasePath + '/auth/login', login);
  }

}
