import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-timesheet',
  templateUrl: './report-timesheet.component.html',
  styleUrls: ['./report-timesheet.component.css']
})
export class ReportTimesheetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
