export enum Study {
    ISCED_0,
    ISCED_1,
    ISCED_2,
    ISCED_3,
    ISCED_4,
    ISCED_5,
    ISCED_6,
    ISCED_7,
    ISCED_8,
    WITHOUT_ISCED,
}
